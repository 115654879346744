<template>

  <surroundBox :name="description" desc="综合加工能力(最大处理、<br/> 收储规模、车间、生产线、设备等)">
    <template slot="content">
      <div style="width:100%;height:100%">
        <div v-for="(item,index) in dataSource" :key="index" class="item">
          <span class="l">{{ item.dimensionName }}</span>
          <span class="r">{{ item.content }}{{item.contentUnit}}</span>
        </div>
      </div>
    </template>
  </surroundBox>
</template>

<script>
import surroundBox from "../surroundBox/surroundBox";

export default {
  name: "wasteProcessingCapacity",
  components: {
    surroundBox,
  },
  data() {
    return {
      description: "废弃物加工产能",
      dataSource: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      return this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO, {
        resourceTypeId: 174
      }).then(res => {
        if (res && res.length > 0) {
          this.dataSource = res
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

.chart-box {
  .item {
    width: calc(100% - 0.17rem - 0.08rem);
    height: 3.7vh;
    font-size: 0.14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #00d9b6, #01306d);
    opacity: 0.75;
    padding: 0 0.14rem;
    margin-left: 0.17rem;

    &:first-child {
      margin-top: 1.5vh;
    }

    &:nth-child(n+2) {
      margin-top: 8px;
    }

    .l {
      color: #fff;

    }

    .r {
      color: #00FFF4;
    }
  }
}
</style>
