<template>
  <div>
    <div class="surroundContainer">
      <div class="header">
        <div class="icon"></div>
        <div class="title">{{ name }}</div>
        <div v-if="desc" class="mark">
          <Tooltip placement="right-start" transfer-class-name="tooltip_pover">
            <div slot="content">
              <div class="title">{{ descTitle }}</div>
              <div class="content" v-html="desc">
              </div>
            </div>
            <div class="mark-icon"></div>
          </Tooltip>
        </div>
      </div>
      <div class="chart-box">
        <slot name="content"></slot>
        <div v-if="unit" class="unit">单位： {{ unit }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "surroundBox",
  props: {
    name: String,
    unit: String,
    desc: String,
    descTitle: {
      type: String,
      default: '释义'
    }
  },
}
</script>

<style lang="less" scoped>
@import url("../common.less");

</style>
